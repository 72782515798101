<template>
  <a-dropdown
    v-model="visible"
    :class="$style.file"
    :trigger="['click']"
    :get-popup-container="
      triggerNode => {
        return triggerNode.parentNode.parentNode
      }
    "
    placement="bottomRight"
  >
    <div :class="$style.icon">
      <i class="ri-smartphone-line" />
    </div>
    <div slot="overlay" class="box-shadow" :class="$style.container">
      <p :class="$style.title">前端预览</p>
      <div :class="$style.scroll">
        <div :class="$style.content">
          <div :class="$style.left">
            <div
              v-for="item in showOtherData"
              :key="item.id"
              :class="[$style.box, active === item.id ? $style.active : null]"
              @click="onChoose(item)"
            >
              <img :class="$style.icon" :src="item.icon" />
              <div>{{ item.name }}</div>
            </div>
            <div :class="$style.line" />
            <div
              v-for="item in showSmallData"
              :key="item.id"
              :class="[$style.box, active === item.id ? $style.active : null]"
              @click="onChoose(item)"
            >
              <img :class="$style.icon" :src="item.icon" />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div :class="$style.right">
            <img :class="$style.code" :src="showImg" />
            <div :class="$style.tip">
              <i class="ri-qr-scan-2-line" />
              {{ tip }}
            </div>
            <BjButton
              v-if="active === 'h5' || active === 'wx_official'"
              :class="$style.copy"
              type="primary"
              @click="onCopy()"
            >
              <i class="ri-file-copy-line left" />
              复制链接
            </BjButton>
            <BjButton v-if="active === 'wx'" :class="$style.copy" type="primary" @click="onDownload()">
              <i class="ri-download-line left" />
              下载小程序码
            </BjButton>
          </div>
        </div>
      </div>
      <div :class="$style.foot">
        <a-row>
          <a-col :class="$style.br" span="12" @click="toPreview()">
            <i class="ri-earth-line" />
            <span>在线前端预览</span>
          </a-col>
          <a-col span="12" @click="toManage()">
            <i class="ri-device-line" />
            <span>进入多端管理</span>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-dropdown>
</template>

<script>
import { systemService } from '@/service'
import { BASE_URL } from '@/utils/variable'

const service = new systemService()

export default {
  name: 'Home',
  data() {
    return {
      visible: false,
      active: 'h5',
      otherData: [
        {
          name: '移动网页端',
          icon: this.oss + '/manage/icon/front/h5.png',
          id: 'h5',
        },
        {
          name: '微信公众号',
          icon: this.oss + '/manage/icon/front/wechat.png',
          id: 'wx_official',
        },
      ],
      smallData: [
        {
          name: '微信小程序',
          icon: this.oss + '/manage/icon/front/miniprogram.png',
          id: 'wx',
        },
        {
          name: '百度小程序',
          icon: this.oss + '/manage/icon/front/baidu.png',
          id: 'baidu',
        },
        {
          name: '字节跳动小程序',
          icon: this.oss + '/manage/icon/front/bytedance.png',
          id: 'tt',
        },
      ],
      data: {},
    }
  },
  computed: {
    showImg() {
      return this.data[this.active] ? this.data[this.active].qr_code : null
    },
    showSmallData() {
      return this.smallData.filter(item => this.data[item.id])
    },
    showOtherData() {
      return this.otherData.filter(item => this.data[item.id])
    },
    tip() {
      switch (this.active) {
        case 'h5':
          return '请使用手机扫码预览'
        case 'wx_official':
          return '请使用微信扫码预览'
        case 'wx':
          return '请使用微信扫码预览'
        default:
          return ''
      }
    },
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.getPreview()
        this.data = data
      } catch (e) {}
    },
    onChoose(item) {
      this.active = item.id
    },
    onCopy() {
      var Url2 = this.data[this.active].link
      var oInput = document.createElement('input')
      oInput.value = Url2
      document.body.appendChild(oInput)
      oInput.select()
      document.execCommand('Copy')
      this.$message.success('复制成功')
      oInput.parentNode.removeChild(oInput) // 删一哈
    },
    onDownload() {
      window.open(BASE_URL + '/api/common/util/file/download?path=' + this.data.wx.qr_code)
    },
    toManage() {
      this.$router.push({
        name: 'terminalManage',
      })
      this.visible = false
    },
    toPreview() {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'home',
          id: '',
        },
      })
    },
  },
}
</script>

<style lang="less" module>
.container {
  width: 350px;
  height: 335px;
  color: #000;
  background-color: #fff;
  border-radius: 6px;
}

.icon {
  width: 48px;
  font-size: 22px;
  line-height: 60px;
  text-align: center;

  :global {
    .ant-dropdown-trigger {
      display: inline-block;
    }
  }
}

.icon:hover {
  background: #fafafa;
  cursor: pointer;
}

.icon > span {
  width: 100%;
}

.title {
  margin: 0;
  padding: 14px;
  font-size: 16px;
}

.scroll {
  height: 233px;
  overflow-x: hidden;
  overflow-y: auto;
}

.content {
  padding: 0 14px;
  display: flex;
  min-height: 233px;

  .left {
    width: 161px;
    border-right: 1px solid #eee;
    padding-right: 10px;

    .box {
      width: 150px;
      height: 44px;
      display: flex;
      align-items: center;
      padding: 12px;
      cursor: pointer;
      font-size: 14px;
      border-radius: 6px;

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }

    .active {
      background-color: #fafafa;
      color: @primary-color;
    }
  }

  .right {
    flex: 1;
    padding-left: 10px;

    .code {
      width: 140px;
      margin: 0 5px;
      height: 140px;
      margin-bottom: 10px;
    }

    .tip {
      font-size: 12px;
      text-align: center;

      i {
        font-size: 18px;
        color: #000;
        vertical-align: -4px;
        margin-right: 10px;
      }
    }

    .copy {
      width: 100%;
      margin-top: 14px;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    border-top: 1px solid #eee;
    margin: 10px 0;
  }
}

.foot {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 48px;
  text-align: center;
  border-top: 1px solid #eee;

  span {
    font-size: 12px;
  }

  i {
    margin-right: 6px;
    color: #000;
    font-size: 18px;
    vertical-align: -4px;
  }

  .br {
    border-right: 1px solid #eee;
  }

  :global {
    .ant-col {
      cursor: pointer;
    }
  }
}
</style>
