<template>
  <div :class="$style.valid">
    <div :class="$style.title">验证码</div>
    <div :class="$style.box">
      <div v-for="(item, index) in code" :key="index" :class="$style.item">
        <a-input
          ref="input"
          v-model="code[index]"
          :style="{ 'border-color': code[index] ? '#1470df' : '' }"
          :max-length="1"
          :class="$style.input"
          @keydown="keydown($event, index)"
          @change="onChange(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ValidCodeInput',
  data() {
    return {
      code: [null, null, null, null, null, null],
    }
  },
  watch: {
    code(val) {
      this.$emit('change', val.find(item => !item) === undefined)
    },
  },
  methods: {
    onChange(index) {
      if (!/^[0-9]*$/.test(this.code[index])) {
        this.code[index] = null
        return
      }
      if (index === 5) return
      if (!this.code[index]) return
      this.$refs.input[index + 1].focus()
    },
    keydown(e, index) {
      if (!index) return
      if (e.keyCode === 8 && !this.code[index]) {
        this.$refs.input[index - 1].focus()
      }
    },
  },
}
</script>

<style lang="less" module>
.valid {
  margin: 16px 0 12px 0;

  .box {
    display: flex;
    justify-content: space-between;
  }

  .input {
    width: 40px;
    height: 40px;
    text-align: center;
  }

  .title {
    margin-bottom: 10px;
    color: #000;
    font-size: 14px;
  }

  .item {
    :global {
      .ant-input {
        padding: 0;
        color: @primary-color;
        font-size: 20px;
        border: 1px solid #eee;
        border-radius: 4px;
      }

      .ant-input:hover {
        border-color: @primary-color;
      }

      .ant-input:focus {
        border: 1px solid @primary-color !important;
      }
    }
  }
}
</style>
