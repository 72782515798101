<template>
  <div v-if="!$route.meta.hideBread" :class="$style.bread">
    <span v-for="(item, index) in bread" :key="index">
      <span :class="item.allow ? null : $style.allow" @click="to(item)"> {{ item.title }}</span>
      <span v-if="index !== bread.length - 1">{{ index === bread.length - 1 ? '' : '&nbsp;/&nbsp;' }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BjBread',
  data() {
    return {
      bread: [],
    }
  },
  watch: {
    '$route.path': function () {
      this.getNode()
    },
  },
  created() {
    this.getNode()
  },
  methods: {
    to(item) {
      if (!item.allow) {
        this.$router.push({
          name: item.name,
        })
      }
    },
    getNode() {
      if (this.$route.meta.fNode) {
        this.bread = this.$route.meta.fNode.concat([
          {
            title: this.$route.meta.title,
            allow: true,
          },
        ])
        return
      }
      this.bread = [
        {
          title: this.$route.meta.title,
          allow: true,
        },
      ]
    },
  },
}
</script>

<style lang="less" module>
.bread {
  .allow {
    cursor: pointer;
  }

  .allow:hover {
    color: @primary-color;
  }
}
</style>
