<template>
  <div :class="$style.box">
    <a-layout-sider
      :width="140"
      :collapsed="collapsed"
      :collapsed-width="60"
      :class="$style.sidebar"
      collapsible
      theme="light"
      breakpoint="lg"
      :trigger="null"
    >
      <div :class="$style.collapsed">
        <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="toggleCollapsed" />
      </div>
      <a-menu v-model="activeMenu" mode="inline" theme="light" :class="$style.menu" :inline-collapsed="collapsed">
        <a-menu-item
          v-for="item in menu[curMode]"
          :id="item.identifier"
          :key="item.identifier"
          title=""
          style="height: 48px; margin: 0 0 12px 0"
          :class="$style.menuItem"
          @click="handleClick(item)"
          @mouseenter="menuIn(item)"
          @mouseleave="menuOut(item)"
        >
          <div v-if="item.identifier === activeMenu[0]" :class="$style.active" />
          <a-tooltip placement="right" :title="sideChirdShow && collapsed ? item.name : ''">
            <div :class="$style.iconbox">
              <img :style="tooltipStyle" :src="item.icon" alt="" />
              <span v-if="!collapsed">{{ item.name }}</span>
            </div>
          </a-tooltip>
        </a-menu-item>
      </a-menu>
      <div :class="$style.mode" @mouseleave="modeOut()">
        <transition name="plus-icon">
          <div v-if="mode" :class="[$style.modeMenu]">
            <div
              :class="[$style.modeItem, curMode === 'menu' ? $style.modeItemActive : null]"
              @click="onSelectMode('menu')"
            >
              <a-tooltip :title="collapsed ? '系统菜单模式' : ''" placement="right">
                <i class="ri-home-3-line" />
              </a-tooltip>
              <span v-if="!collapsed">系统菜单模式</span>
            </div>
            <div
              :class="[$style.modeItem, curMode === 'app' ? $style.modeItemActive : null]"
              @click="onSelectMode('app')"
            >
              <a-tooltip :title="collapsed ? '应用菜单模式' : ''" placement="right">
                <i class="ri-apps-2-line" />
              </a-tooltip>
              <span v-if="!collapsed">应用菜单模式</span>
            </div>
          </div>
        </transition>
        <div :class="$style.modeIcon" @mouseenter="modeIn()">
          <i v-if="mode" class="ri-arrow-down-s-line" />
          <i v-else class="ri-arrow-up-s-line" />
        </div>
      </div>
    </a-layout-sider>
    <BjSidebarChird v-if="sideChirdShow" :title="title" :data="childData" />
    <BjSidebarModal
      v-show="modalShow"
      :title="title"
      :data="modalData"
      :collapsed="collapsed"
      :side-chird-show="sideChirdShow"
      :move-style="moveStyle"
      @mouseover="menuIn()"
      @mouseout="menuOut()"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BjSidebarChird from './BjSidebarChird'
import BjSidebarModal from './BjSidebarModal'

export default {
  name: 'BjSidebar',
  components: {
    BjSidebarChird,
    BjSidebarModal,
  },
  data() {
    return {
      childData: [],
      title: '',
      oldMenu: '',
      modalShow: false,
      modalData: [],
      timer: null,
      mode: false,
      moveStyle: {},
    }
  },
  computed: {
    ...mapState(['menu']),
    collapsed: {
      get() {
        return this.$store.state.collapsed
      },
      set(val) {
        this.$store.commit('COLLAPSED', val)
      },
    },
    sideChirdShow: {
      get() {
        return this.$store.state.sideChirdShow
      },
      set(val) {
        this.$store.commit('SIDECHIRDSHOW', val)
      },
    },
    activeMenu: {
      get() {
        return this.$store.state.activeMenu
      },
      set(val) {
        this.$store.commit('ACTIVEMENU', val)
      },
    },
    curMode: {
      get() {
        return this.$store.state.mode
      },
      set(val) {
        this.$store.commit('MODE', val)
      },
    },
    tooltipStyle() {
      return this.sideChirdShow && this.collapsed ? { 'margin-right': '15px' } : ''
    },
  },
  watch: {
    activeMenu: {
      handler(val) {
        // 分了两个mode   app  and  menu    应用模式 和 系统模式
        this.menu[this.curMode].map(item => {
          if (val[0] === item.identifier) {
            this.childData = item.children
            this.title = item.name
          }
        })
      },
      immediate: true,
    },
  },
  methods: {
    onSelectMode(_type) {
      this.curMode = _type
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed
    },
    handleClick(item) {
      this.modalShow = false
      this.childData = item.children
      this.title = item.name
      if (this.oldMenu === item.identifier && this.sideChirdShow) {
        this.sideChirdShow = false
      } else {
        this.oldMenu = item.identifier
        this.sideChirdShow = true
      }
      if (item.children && item.children[0].children) {
        this.$router.push(item.children[0].children[0].page)
      }
    },
    moveMenu(val) {
      let top = 0
      let height = null
      let dom = document.getElementById(val).offsetTop // dom 位置
      let modal = document.getElementById('SidebarModal').offsetHeight // 弹窗自然高度
      let clientHeight = document.documentElement.clientHeight // 浏览器高度
      if (modal > clientHeight - 60 - 20) {
        height = clientHeight - 60 - 20 + 'px'
        top = 10
      } else if (dom + modal > clientHeight - 60 - 20) {
        top = dom - (dom + modal - clientHeight) - 10 - 60
      } else {
        top = dom
      }
      this.moveStyle = {
        top: top + 'px',
      }
      if (height) {
        this.moveStyle.height = height
      }
    },
    menuIn(item) {
      if (!item) {
        this.modalShow = true
        clearTimeout(this.timer)
        return
      }
      if (!this.sideChirdShow) {
        this.modalShow = true
        this.modalData = item.children
        this.title = item.name
        clearTimeout(this.timer)
      }
      this.moveStyle = {}
      this.$nextTick(() => {
        this.moveMenu(item.identifier)
      })
    },
    menuOut() {
      if (!this.sideChirdShow) {
        this.timer = setTimeout(() => {
          this.modalShow = false
        }, 200)
      }
    },
    modeIn() {
      this.mode = true
    },
    modeOut() {
      this.mode = false
    },
  },
}
</script>

<style lang="less" module>
@background: #fff;

.box {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  background-color: #fafafa;
  transform: translate3d(0, 0, 0);
  transition: width 0.3s;
  user-select: none;
}

.sidebar {
  overflow-x: hidden;
  overflow-y: auto;
  color: @text-color;
  background: @background;
  box-shadow: 3px 4px 5px rgba(#999, 0.05);
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

.menu {
  height: calc(100vh - 145px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.menu::-webkit-scrollbar {
  width: 0 !important;
}

.collapsed {
  height: 52px;
  margin-left: 20px;
  color: #000;
  font-size: 20px;
  line-height: 52px;
}

.iconbox {
  position: absolute;
  top: 4px;
  left: 16px;
  display: flex;
  align-items: center;
  height: 40px;
  // color: #000;
  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }

  span {
    margin-left: 15px;
  }
}

.active {
  position: absolute;
  top: 14px !important;
  left: 0;
  width: 2px;
  height: 20px;
  background-color: @primary-color;
  border: none;
  border-radius: 0 4px 4px 0;
}

.mode {
  position: absolute;
  bottom: 0;
  z-index: 99;
  width: 100%;
  font-size: 12px;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  transition: height 0.3s;

  &-icon {
    height: 30px;
    line-height: 30px;

    i {
      color: #000;
      font-size: 24px;
    }
  }

  &-menu {
    padding: 0 15px;
  }

  .mode-item {
    height: 30px;
    margin-top: 10px;
    color: #000;
    line-height: 30px;
    border-radius: 4px;
    cursor: pointer;

    i {
      font-size: 20px;
      vertical-align: -5px;
    }

    span {
      margin-left: 5px;
    }
  }

  .mode-item:hover {
    background: #fafafa;
  }

  .mode-item-active {
    color: @primary-color;
    background: #fafafa;
  }
}
</style>

<style>
.plus-icon-enter-active {
  transition: opacity 0.5s;
}
.plus-icon-enter {
  opacity: 0;
}
.plus-icon-leave-active {
  transition: opacity 0.5s;
}
.plus-icon-leave-to {
  opacity: 0;
}
</style>
