<template>
  <div :class="$style.header">
    <a-row>
      <a-col :class="$style.logo" span="12">
        <img :src="user.cultural_cloud_logo" alt="" /> {{ user.cultural_cloud_name }}
      </a-col>
      <a-col :class="$style.btns" span="12">
        <a-tooltip placement="bottom" title="素材任务">
          <div id="car" :class="$style.icon">
            <BjFile />
          </div>
        </a-tooltip>
        <!-- <a-tooltip placement="bottom" title="全局搜索">
          <div :class="$style.icon">
            <i class="ri-search-2-line" />
          </div>
        </a-tooltip> -->
        <!-- <a-tooltip placement="bottom" title="我的订购">
          <div :class="$style.icon">
            <BjShopCar />
          </div>
        </a-tooltip> -->
        <a-tooltip placement="bottom" title="前端预览">
          <!-- <div :class="$style.icon">
            <i class="ri-smartphone-line" />
          </div> -->
          <BjPreview />
        </a-tooltip>
        <!-- <a-tooltip placement="bottom" title="消息通知">
          <div :class="$style.icon">
            <i class="ri-notification-line" />
          </div>
        </a-tooltip> -->
        <!-- <a-tooltip placement="bottom" title="帮助中心">
          <div :class="$style.icon">
            <i class="ri-question-line" />
          </div>
        </a-tooltip> -->
        <div :class="$style.user">
          <a-dropdown
            placement="bottomRight"
            :get-popup-container="
              (triggerNode) => {
                return triggerNode.parentNode;
              }
            "
            :trigger="['click']"
          >
            <div v-if="user.avatar" :class="$style.userName">
              <img :src="user.avatar" alt="" />
              <span>{{ user.real_name }}</span>
            </div>
            <div v-else :class="$style.userName">
              <a-avatar v-if="user.real_name" :class="$style.avatar">{{
                user.real_name.length > 2
                  ? user.real_name.substring(0, 1).toUpperCase()
                  : user.real_name
              }}</a-avatar>
              <span>{{ user.real_name }}</span>
            </div>
            <div slot="overlay" :class="$style.userModal">
              <div :class="$style.top">
                <p>登录账号</p>
                <p :class="$style.name">{{ user.account }}</p>
                <span :class="$style.role">
                  <span>
                    {{ user.role_name }}
                  </span>
                </span>
              </div>
              <div :class="$style.btnss">
                <div :class="$style.btn" @click="to()">
                  <i class="ri-account-circle-line" />账号信息
                </div>
                <div :class="[$style.btn, $style.mt2]" @click="onSwitch()">
                  <i class="ri-refresh-line" />切换运城文旅云
                </div>
                <div :class="$style.line" />
                <div :class="$style.btn" @click="loginOut()">
                  <i class="ri-logout-box-line" />退出登录
                </div>
              </div>
            </div>
          </a-dropdown>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import BjFile from "./BjFile";
import BjPreview from "./BjPreview";
import BjShopCar from "./BjShopCar";

export default {
  name: "BjHeader",
  components: {
    BjFile,
    BjPreview,
    BjShopCar,
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["LOGOUT"]),
    loginOut() {
      this.LOGOUT();
    },
    to() {
      this.$router.push({
        name: "systemInfo",
      });
    },
    onSwitch() {
      this.$router.push({
        name: "control",
      });
    },
  },
};
</script>

<style lang="less" module>
.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  height: 60px;
  margin-bottom: 5px;
  padding: 0 20px;
  color: #101010;
  background-color: #fff;
  border: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  user-select: none;

  .logo {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 60px;

    img {
      width: 36px;
      height: 36px;
      margin: 0 12px 0 0;
      border-radius: 100%;
    }
  }

  .btns {
    display: flex;
    justify-content: flex-end;
    height: 60px;

    .user {
      max-width: 200px;
      padding: 0 13px;
      font-size: 14px;
      line-height: 60px;
      cursor: pointer;

      :global {
        .ant-avatar {
          width: 36px;
          height: 36px;
          margin-right: 5px;
          color: @primary-color;
          line-height: 36px;
          background: rgba(20, 112, 223, 0.2);
        }
      }

      &-name {
        display: flex;
        align-items: center;

        img {
          width: 36px;
          height: 36px;
          margin-right: 5px;
          border: 1px solid #eee;
          border-radius: 36px;
        }

        & > span {
          margin-left: 5px;
        }
      }
    }

    .user:hover {
      background: #fafafa;
    }

    .icon {
      width: 48px;
      font-size: 22px;
      line-height: 60px;
      text-align: center;

      :global {
        .ant-dropdown-trigger {
          display: inline-block;
        }
      }
    }

    .icon:hover {
      background: #fafafa;
      cursor: pointer;
    }

    .icon > span {
      width: 100%;
    }
  }
}

.user-modal {
  width: 180px;
  height: 240px;
  overflow: hidden;
  font-size: 14px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .top {
    height: 64px;
    padding: 10px;
    color: #fff;
    background: @primary-color;

    p {
      margin-bottom: 6px;
    }

    .name {
      font-size: 12px;
    }

    .role {
      position: absolute;
      top: 2px;
      right: -15px;
      transform: scale(0.5);

      span {
        padding: 6px;
        font-size: 20px;
        background: rgba(250, 250, 250, 0.2);
        border-radius: 4px;
      }
    }
  }

  .btnss {
    padding: 10px;

    .btn {
      height: 44px;
      padding-left: 12px;
      color: #000;
      line-height: 44px;
      border-radius: 6px;
      cursor: pointer;

      i {
        margin-right: 8px;
        font-size: 20px;
        vertical-align: -4px;
      }
    }

    .mt2 {
      margin-top: 2px;
    }

    .btn:hover {
      color: @primary-color;
      background: #fafafa;
    }

    .line {
      margin: 10px 0;
      border-bottom: 1px solid #eee;
    }
  }
}
</style>
