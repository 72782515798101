<template>
  <a-dropdown
    v-model="visible"
    :trigger="['click']"
    :get-popup-container="
      triggerNode => {
        return triggerNode.parentNode.parentNode
      }
    "
    placement="bottomRight"
  >
    <span>
      <a-badge :class="$style.badge" :count="data.list.length" :offset="[-6, 12]">
        <slot>
          <i :class="$style.icon" class="ri-shopping-cart-line" />
        </slot>
      </a-badge>
    </span>
    <div slot="overlay" class="box-shadow" :class="$style.container">
      <p :class="$style.title">我的订购</p>
      <div :class="$style.scroll">
        <div v-for="item in data.list" :key="item.id" :class="$style.item">
          <div :class="$style.cover">
            <img :src="item.cover" />
            <div :class="$style.type">
              {{ item.foreign_type_name }}
            </div>
          </div>
          <div :class="$style.text">
            <p :class="$style.name">{{ item.name }}</p>
            <p :class="$style.desc">{{ item.properties_title }}</p>
          </div>
          <div :class="$style.delete">
            <i class="ri-delete-bin-7-line" @click="onDelete(item.id)" />
          </div>
        </div>
        <div v-if="!data.list.length" :class="$style.noData">
          <img :src="oss + '/manage/media/state/no_data.png'" />
          <p>暂时没有订购</p>
        </div>
      </div>
      <div :class="$style.foot">
        合计：{{ data.amount }}
        <a-tooltip>
          <template slot="title"> 当前展示的是订购产品合计价格，如有优惠，请在下单页面查看。 </template>
          <i :class="$style.ques" class="ri-question-line" />
        </a-tooltip>
        <BjButton type="primary" :class="$style.btn" @click="onSubmit()">
          <i class="left ri-file-upload-line" />
          提交订单
        </BjButton>
      </div>
    </div>
  </a-dropdown>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import { centerService } from '@/service'

const service = new centerService()

export default {
  name: 'BjShopCar',
  data() {
    return {
      data: {
        list: [],
        amount: 0,
      },
      visible: false,
    }
  },
  computed: {
    ...mapState(['refreshShopCar']),
  },
  watch: {
    refreshShopCar() {
      this.getList()
    },
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapMutations(['REFRESHSHOPCAR']),
    async getList() {
      try {
        const { data } = await service.getList()
        this.data = data
      } catch (e) {}
    },
    onSubmit() {
      //   if (!this.data.list.length) {
      //     return
      //   }
      this.$router.push({
        name: 'commonOrder',
      })
    },
    async onDelete(id) {
      try {
        await service.deleteCar({
          id: id,
        })
        this.$message.success('删除成功')
        this.getList()
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.container {
  width: 350px;
  height: 400px;
  color: #000;
  background-color: #fff;
  border-radius: 6px;
}

.scroll {
  height: 294px;
  overflow-x: hidden;
  overflow-y: auto;
}

.foot {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 32px;
  border-top: 1px solid #eee;
  padding: 9px 14px;
  font-size: 12px;

  .btn {
    float: right;
  }

  .ques {
    font-size: 18px;
    vertical-align: -5px;
    margin: 4px;
  }
}

.item {
  display: flex;
  align-items: center;
  height: 78px;
  padding: 14px;

  .cover {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 6px;
    object-fit: cover;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }

    .type {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: @primary-color;
      height: 18px;
      line-height: 18px;
      text-align: center;
      border-radius: 0px 0px 6px 6px;
      color: #fff;
    }
  }

  .text {
    flex: 1;
    p {
      margin: 0;
    }

    .name {
      margin-bottom: 8px;
      color: #000;
      font-size: 12px;
    }

    .desc {
      font-size: 12px;
      color: #5c5c5c;
    }
  }

  .delete {
    i {
      font-size: 18px;
    }
  }
}

.item:hover {
  background: #fafafa;

  .agin {
    display: block;
  }

  .default {
    display: none;
  }
}

.title {
  margin: 0;
  padding: 14px;
  font-size: 16px;
}

.no-data {
  padding-top: 40px;
  color: #5c5c5c;
  text-align: center;

  img {
    width: 170px;
  }
}

.badge {
  width: 48px;
  font-size: 22px;
  line-height: 60px;
  text-align: center;

  :global {
    .ant-badge-count {
      transform: scale(0.8);
    }

    .ant-badge {
      top: -4px;
    }
  }
}
</style>
