<template>
  <div id="SidebarModal" :class="$style.modal" :style="style" @mouseenter="menuIn()" @mouseleave="menuOut()">
    <div :class="$style.title">{{ title }}</div>
    <div v-for="gItem in data" :key="gItem.identifier" :class="$style.group">
      <div :class="$style.groupName">{{ gItem.name }}</div>
      <div
        v-for="item in gItem.children"
        :key="item.identifier"
        :class="[$style.item, item.identifier === activeChild ? $style.active : null]"
        @click="router(item.page)"
      >
        <i :class="item.icon" alt="" />
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BjSidebarModal',
  props: {
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    collapsed: {
      type: Boolean,
      required: true,
    },
    sideChirdShow: {
      type: Boolean,
      required: true,
    },
    moveStyle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['activeChild']),
    style() {
      let left = this.collapsed ? (this.sideChirdShow ? '230px' : '70px') : this.sideChirdShow ? '310px' : '150px'
      return {
        left: left,
        ...this.moveStyle,
      }
    },
  },
  methods: {
    menuIn() {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('mouseover')
    },
    menuOut() {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('mouseout')
    },
    router(path) {
      // 如果跳转编辑器
      if (path === '/editor') {
        this.$store.commit('EDITOR', {
          page_type: 'index',
        })
        this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
        // let routeData = this.$router.resolve({
        //   name: 'editor',
        // })
        // window.open(routeData.href, '_blank')
        this.$router.push(path)
        return
      }
      this.$router.push(path)
    },
  },
}
</script>

<style lang="less" module>
@background: #fff;

.modal {
  position: absolute;
  top: 100px;
  z-index: 99;
  width: 160px;
  padding-bottom: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  color: @text-color;
  background: @background;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: top 0.2s, height 0.2s;

  .title {
    height: 52px;
    color: #000;
    font-size: 16px;
    line-height: 52px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }

  .group {
    padding: 0 10px;
    color: #5c5c5c;
    font-size: 12px;

    &-name {
      margin: 14px 0 10px;
      color: #5c5c5c;
    }
  }

  .item {
    position: relative;
    height: 44px;
    margin-bottom: 2px;
    padding: 0 12px;
    color: #000;
    font-size: 14px;
    line-height: 44px;
    border-radius: 6px;
    cursor: pointer;

    span {
      margin-left: 8px;
    }

    i {
      font-size: 20px;
      vertical-align: -4px;
    }
  }

  .item:last-child {
    margin-bottom: 0;
  }

  .item:hover {
    background: #fafafa;
  }

  .active {
    color: @primary-color;
    background: #fafafa;
  }
}
</style>
