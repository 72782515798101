<template>
  <div :class="$style.sidebar">
    <div :class="$style.title">{{ title }}</div>
    <div v-for="gItem in data" :key="gItem.identifier" :class="$style.group">
      <div :class="$style.groupName">
        {{ gItem.name }}
      </div>
      <div
        v-for="item in gItem.children"
        :key="item.identifier"
        :class="[$style.item, item.identifier === activeChild ? $style.active : null]"
        @click="router(item.page, item)"
      >
        <i :class="item.icon" alt="" />
        <span>{{ item.name }}</span>
        <i v-if="item.is_target === 1" :class="$style.target" class="ri-arrow-right-up-line" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BjSidebarChird',
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(['activeChild']),
  },
  methods: {
    router(path, item) {
      if (item.is_target === 1) {
        window.open(item.page, '_blank')
        return
      }
      // 如果跳转编辑器
      if (path === '/editor') {
        this.$store.commit('EDITOR', {
          page_type: 'index',
        })
        this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
        // let routeData = this.$router.resolve({
        //   name: 'editor',
        // })
        // window.open(routeData.href, '_blank')
        this.$router.push(path)
        return
      }
      this.$router.push(path)
    },
  },
}
</script>

<style lang="less" module>
@background: #fff;

.sidebar {
  width: 160px;
  overflow-x: hidden;
  overflow-y: auto;
  color: @text-color;
  background: @background;
  box-shadow: 3px 4px 5px rgba(#999, 0.05);

  .title {
    height: 52px;
    color: #000;
    font-size: 16px;
    line-height: 52px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }

  .group {
    padding: 0 10px;
    color: #000;
    font-size: 12px;

    &-name {
      margin: 14px 0 10px;
      color: #5c5c5c;
    }
  }

  .item {
    position: relative;
    height: 44px;
    margin-bottom: 2px;
    padding: 0 12px;
    color: #000;
    font-size: 14px;
    line-height: 44px;
    border-radius: 6px;
    cursor: pointer;

    i {
      font-size: 20px;
      vertical-align: -4px;
    }

    span {
      margin-left: 8px;
    }

    .target {
      position: absolute;
      top: 2px;
      right: 4px;
      color: #bbb;
      font-size: 16px;
    }
  }

  .item:last-child {
    margin-bottom: 0;
  }

  .item:hover {
    background: #fafafa;
  }

  .active {
    color: @primary-color;
    background: #fafafa;
  }
}
</style>
